@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 36px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    padding: 36px 41px 32px 41px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-bottom: 0 !important;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.avatarWrapper {
  margin-top: 34px;
}

.nameWrapper {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #6E6E73;
  padding-top: 6px;
}