@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--colorFail);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    /* height: 32px; */
    margin: 0;
    padding: 0;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
    margin-top: 18px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 0;
  }

  & textarea {
    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: -0.3047058880329132px;
      text-align: left;
      color: #6A6A6A;
    }
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}