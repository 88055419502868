@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 2px 0 6px 0;
  }
}

.showOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: center;
}