@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px #0000004d;
  padding: 18px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
  &.signedUp{
    box-shadow:none;
  }
  &.boothConfigured{
    box-shadow: 0px 3px 6px 0px #000;
  }
  &.onboarded{
    box-shadow: 0px 3px 6px 0px #0000004d;
  }
  &.activeEngaged{
    box-shadow: 0px 3px 6px 0px #7C3AED;
  }
  &.tradeShow{
    box-shadow: 0px 3px 6px 0px #4AA6F8;
  }
}

.complete {
  border: 1px solid #7C3AED;
}

.incomplete {
  border: 1px solid #000;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: #fff; /* Loading BG color */
  border-radius: 50%;
  height: 100px;
  width: 104px;
  margin: 0 auto;
  overflow: hidden;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  & img {
    object-fit: contain;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  color: #7c3aed;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.3047058880329132px;
  text-align: left;
  word-break: break-word;
  word-wrap: break-all;
  max-width: 200px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.booths {
  color: #4a4a4a;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: -0.2px;
  line-height: 16px;
  margin: 2px 0;
  padding: 0;
  white-space: normal;
  & ul {
    margin: 0;
    padding: 0;
    padding-left: 0px;
    & li {
      list-style: none;
      color: #4a4a4a;
      font-size: 13px;
      font-style: normal;
      letter-spacing: -0.2px;
      line-height: 16px;
      padding-left: 0px;
      &::before {
        content: '•';
        margin-right: 4px; /* Adjust this value to reduce or increase the gap */
      }
    }
  }
}

.details {
  color: #4a4a4a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 2px 0;
  padding: 0;
  white-space: normal;

  & span {
    font-weight: 800;
  }
}

.title {
  display: inline-block;
}

.businessDefaultImage{
  width: 90%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
  /* border-radius: 50%; */
  background-color: #fff;
  margin: 0 auto;
}